<template>
    <div id="replacementPerformanceComparison" style="width: 100%; height: 500px;"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import axios from "axios";

export default {
    name: "ComparisonChart",
    props: {
        firstRange: { type: String, required: true },
        secondRange: { type: String, required: true },
        region: { type: String, required: true },
    },
    data() {
        return {
            chart: null,
            yearColors: {
                "2025": "#F6B6EB",
                "2024": "#4E80ED",
                "2023": "#F2BC80",
                "2022": "#55B586",
                "<=2021": "#C2B6F6",
                "Unknown": "#F6B6EB",
            },
        };
    },
    methods: {
        async fetchData() {
            const apiUrl = `/api/distribution/replacement/comparison?first_range=${this.firstRange}&second_range=${this.secondRange}&region=${this.region}`;
            try {
                const response = await axios.get(apiUrl);
                const rawData = response.data?.data;
                if (rawData) {
                    return this.transformData(rawData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                return [];
            }
        },
        transformData(rawData) {
            const chartData = [];
            for (const month in rawData) {
                rawData[month].forEach((entry, index) => {
                    const [monthLabel, year] = entry.label.split(" "); // Pisahkan bulan & tahun

                    chartData.push({
                        month: monthLabel,
                        label: monthLabel, // Hanya nama bulan di label
                        value1: index === 0 ? entry.value : null,
                        value2: index === 1 ? entry.value : null,
                        year, // Tambahkan tahun untuk pewarnaan
                    });
                });
            }
            return chartData;
        },
        async createChart() {
            const chartData = await this.fetchData();

            const chart = am4core.create("replacementPerformanceComparison", am4charts.XYChart);
            chart.data = chartData;

            const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "label";
            categoryAxis.renderer.labels.template.wrap = true; // Agar teks tidak hilang
            categoryAxis.renderer.minGridDistance = 30; // Sesuaikan agar semua bulan terlihat
            categoryAxis.renderer.labels.template.truncate = false; // Hindari pemotongan label

            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "Values";

            const yearColors = {
                '2025': '#F6B6EB',
                '2024': '#4E80ED',
                '2023': '#F2BC80',
                '2022': '#55B586',
                '<=2021': '#C2B6F6',
                'Unknown': '#F6B6EB'
            };

            const series1 = chart.series.push(new am4charts.ColumnSeries());
            series1.dataFields.valueY = "value1";
            series1.dataFields.categoryX = "label";
            series1.name = "First Range";
            series1.columns.template.tooltipText = "{label}: [bold]{valueY}[/]";
            series1.columns.template.column.cornerRadiusTopLeft = 8;
            series1.columns.template.column.cornerRadiusTopRight = 8;

            const series2 = chart.series.push(new am4charts.ColumnSeries());
            series2.dataFields.valueY = "value2";
            series2.dataFields.categoryX = "label";
            series2.name = "Second Range";
            series2.columns.template.tooltipText = "{label}: [bold]{valueY}[/]";
            series2.columns.template.column.cornerRadiusTopLeft = 8;
            series2.columns.template.column.cornerRadiusTopRight = 8;

            // Assign colors dynamically
            chartData.forEach(data => {
                const year = data.year;
                const color = yearColors[year] || "#808080"; // Default to grey if not found
                if (data.value1 !== null) {
                    series1.columns.template.fill = am4core.color(color);
                    series1.columns.template.stroke = am4core.color(color);
                }
                if (data.value2 !== null) {
                    series2.columns.template.fill = am4core.color(color);
                    series2.columns.template.stroke = am4core.color(color);
                }
            });

            chart.cursor = new am4charts.XYCursor();
            chart.legend = new am4charts.Legend();
            chart.tooltip.pointerOrientation = "vertical";
            chart.tooltip.animationDuration = 150;

            this.chart = chart;
        },
    },
    watch: {
        firstRange: "createChart",
        secondRange: "createChart",
        region: "createChart",
    },
    mounted() {
        this.createChart();
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    },
};
</script>


<style scoped>
/* Optional: Customize chart styles */
</style>