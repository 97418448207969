<template>
  <div id="ExistingIdChartStackedColumn"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  name: "ExistingIdChartStackedColumn",
  props: {
    startDate: { type: String, required: true },
    endDate: { type: String, required: true },
    selectedYearGlobal: { type: [String, Number], default: "All Years" },
    selectedChartDealer: { type: Object, default: null },
    chartData: { type: Array, required: true },
  },
  data() {
    return {
      chart: null,
      noDataLabel: null,
      colors: ["#4e80ed", "#55b586", "#c2b6f6", "#f2bc80", "#F6B6EB"],
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    cutoffYear() {
      return this.currentYear - 3;
    },
    yearGroups() {
    return [
        { field: this.currentYear.toString(), name: this.currentYear.toString() },
        { field: (this.currentYear - 1).toString(), name: (this.currentYear - 1).toString() },
        { field: (this.currentYear - 2).toString(), name: (this.currentYear - 2).toString() },
        { field: "Older", name: `<=${this.cutoffYear}` },
        { field: "Unknown", name: "Unknown" },
      ];
    },
  },
  watch: {
    chartData: {
      handler(newData) {
        if (this.chart) {
          this.updateChart(newData); // Update chart when data changes
          this.toggleNoDataLabel(newData);
        }
      },
      immediate: true,
    },
    selectedChartDealer: {
      handler() {
        if (this.chart) {
          console.log("Dealer selected:", this.selectedChartDealer ? this.selectedChartDealer.loc_code : "None");
          this.toggleNoDataLabel(this.chartData); // Check "No Data Available" when dealer selection changes
        }
      },
      immediate: true,
    },
    selectedYearGlobal() {
      if (this.chart) {
        this.updateChart(); // Refresh chart when selected year changes
      }
    },
  },
  mounted() {
    this.createChart(); // Initialize the chart on mount
    this.updateChart(this.chartData); // Ensure all years are shown initially
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose(); // Clean up on destroy
      this.chart = null;
    }
  },
  methods: {
    createChart() {
      const chart = am4core.create("ExistingIdChartStackedColumn", am4charts.XYChart);
      if (!chart) {
        console.error("Chart failed to initialize");
        return;
      }

      chart.data = this.chartData;

      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "month";

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Registrations";

      chart.cursor = new am4charts.XYCursor();
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

      this.chart = chart;
      this.createNoDataLabel();
      this.toggleNoDataLabel(this.chartData);
    },
    updateChart(data = this.chartData) {
      if (!this.chart) return; // Check chart existence

      this.chart.series.clear(); // Clear all series

      const selectedYear = this.selectedYearGlobal?.toString() || "All Years";
      const showAllYears = selectedYear === "All Years";

      // Create series for each year group
      this.yearGroups.forEach((group, index) => {
        const color = this.colors[index % this.colors.length];
        const shouldShow = showAllYears || this.showSeries(group.name, selectedYear);

        if (shouldShow) {
          this.createSeries(this.chart, group.field, group.name, color);
        }
      });
      // Update category axis based on available months in data
      const categoryAxis = this.chart.xAxes.getIndex(0);
      if (categoryAxis) {
        const uniqueMonths = [...new Set(data.map((entry) => entry.month))]; // Get unique months from data
        categoryAxis.dataFields.category = "month";
        categoryAxis.renderer.minGridDistance = 50; // Adjust spacing between labels
        categoryAxis.renderer.labels.template.adapter.add("text", (text, target) => {
          // Ensure month labels are displayed correctly
          return target.dataItem?.category || "";
        });
        this.chart.data = data; // Update chart data
      }
      // Update legend to reflect visible series
      const visibleSeries = this.chart.series.values.filter((series) => !series.isHidden);
      this.chart.legend.data = visibleSeries;
      this.chart.legend.invalidate();
      this.chart.invalidateRawData();

      // Show or hide "No Data Available" label based on data and dealer selection
      this.toggleNoDataLabel(data);
    },
    showSeries(yearGroup, selectedYear) {
      if (selectedYear === "All Years") return true;
      if (yearGroup === selectedYear) return true;
      if (yearGroup.startsWith("<=") && selectedYear.startsWith("<=")) {
        const cutoff = parseInt(selectedYear.slice(2), 10);
        return cutoff <= this.cutoffYear;
      }
      return false;
    },
    createSeries(chart, field, name, color) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "month";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      series.stacked = true;
      series.columns.template.fill = am4core.color(color);
      series.columns.template.stroke = am4core.color(color);
      series.columns.template.column.cornerRadiusTopLeft = 2;
      series.columns.template.column.cornerRadiusTopRight = 2;
      series.columns.template.column.cornerRadiusBottomLeft = 2;
      series.columns.template.column.cornerRadiusBottomRight = 2;
      series.columns.template.width = am4core.percent(50);

      // Custom tooltip
      let tooltip = series.tooltip;
      tooltip.pointerOrientation = "vertical";
      tooltip.background.cornerRadius = 5;
      tooltip.background.fill = am4core.color(color);
      tooltip.background.stroke = am4core.color(color).lighten(-0.2);
      tooltip.background.strokeOpacity = 0.8;
      tooltip.label.fill = am4core.color("#ffffff");
      tooltip.label.textAlign = "middle";

      // Tooltip text
      series.tooltipText = "{name}: [bold]{valueY}[/]";
    },
    createNoDataLabel() {
      if (!this.chart) return;

      this.noDataLabel = this.chart.createChild(am4core.Label);
      this.noDataLabel.align = "center";
      this.noDataLabel.alignTop = "middle";
      this.noDataLabel.role = "noDataLabel";
      this.noDataLabel.html = `<p style="font-weight: bold; font-size: 1.5rem;">No Data Available.</p><p style="margin-top: -0.5rem">Please choose another time or option</p>`;
      this.noDataLabel.hide(); // Initially hidden
    },
    toggleNoDataLabel(data) {
      if (!this.chart) return; // Ensure chart is initialized

      const hasData =
        data &&
        data.some((entry) => {
          return Object.keys(entry).some((key) => key !== "month" && entry[key] > 0);
        });
      const dealerSelected = !!this.selectedChartDealer;

      if (!hasData) {
        this.noDataLabel?.show();
        this.chart.legend?.hide();
      } else {
        this.noDataLabel?.hide();
        // Hanya tampilkan legend untuk series yang memiliki data
        this.chart.legend.data = this.chart.series.values.filter((series) => {
          return data.some((entry) => entry[series.dataFields.valueY] > 0);
        });
        this.chart.legend.invalidate(); // Refresh legend
        this.chart.legend.show();
      }
    },
  },
};
</script>

<style>
#ExistingIdChartStackedColumn {
  width: 100%;
  height: 480px;
  transform: translate3d(0, 10%, 0);
}

#ExistingIdChartStackedColumn g[role="noDataLabel"] {
  transform: translate3d(32%, 20%, 10px);
}

#ExistingIdChartStackedColumn g[aria-label="Legend"] {
  transform: translate(0, 71%);
}
</style>
