<template>
	<div id="replacementPerformanceStackedColumnChart" style="width: 100%; height: 500px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from "axios";

am4core.useTheme(am4themes_animated);

export default {
	name: "StackedColumnChart",
	props: {
		startDate: {
			type: String,
			required: true
		},
		endDate: {
			type: String,
			required: true
		},
		selectedChartDealer: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			chart: null,
			yearOrder: ['2025', '2024', '2023', '2022', 'Older', 'Unknown'],
			yearColors: {
				'2025': '#FF5733',
				'2024': '#4E80ED',
				'2023': '#F2BC80',
				'2022': '#55B586',
				'<=2021': '#C2B6F6',
				'Unknown': '#F6B6EB'
			}
		};
	},
	watch: {
		startDate() {
			this.updateChart();
		},
		endDate() {
			this.updateChart();
		},
		selectedYear() {
			this.updateChart();
		},
		selectedChartDealer() {
			this.updateChart();
		}
	},
	mounted() {
		this.createChart();
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
	methods: {
		createChart() {
			this.fetchData().then(chartData => {
				let chart = am4core.create("replacementPerformanceStackedColumnChart", am4charts.XYChart);
				chart.data = chartData;
				chart.dataSource.keepEmptyValues = true; // Keep layout stable

				let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
				categoryAxis.dataFields.category = "month";
				categoryAxis.renderer.grid.template.location = 0;
				categoryAxis.renderer.minGridDistance = 20;
				categoryAxis.renderer.labels.template.rotation = 0;
				categoryAxis.renderer.labels.template.truncate = false;
				categoryAxis.renderer.labels.template.adapter.add("text", (text) => text);

				let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
				valueAxis.title.text = "Registrations";
				valueAxis.min = 0; // Prevent chart from shifting due to missing data

				// Get available years from the data
				const availableYears = this.getAvailableYears(chartData);

				// Create series in specified order
				this.yearOrder.forEach(year => {
					if (availableYears.includes(year)) {
						const displayName = this.getDisplayName(year);
						const color = this.yearColors[year];
						this.createSeries(chart, year, displayName, color);
					}
				});

				chart.cursor = new am4charts.XYCursor();
				chart.legend = new am4charts.Legend();
				chart.tooltip.pointerOrientation = "vertical";
				chart.tooltip.animationDuration = 150;

				this.chart = chart;
			});
		},
		updateChart() {
			this.fetchData().then(chartData => {
				if (this.chart) {
					// Remove all existing series
					while (this.chart.series.length > 0) {
						this.chart.series.removeIndex(0).dispose();
					}

					// Get available years from the filtered data
					const availableYears = this.getAvailableYears(chartData);

					// Create series in specified order
					this.yearOrder.forEach(year => {
						if (availableYears.includes(year)) {
							const displayName = this.getDisplayName(year);
							const color = this.yearColors[year];
							this.createSeries(this.chart, year, displayName, color);
						}
					});

					this.chart.data = chartData;
					this.chart.invalidateData();
				}
			});
		},
		getDisplayName(year) {
			if (year === "Older") {
				return "<=2021";
			}
			return year;
		},
		getAvailableYears(data) {
			const years = new Set();
			data.forEach(item => {
				Object.keys(item).forEach(key => {
					if (key !== 'month' && item[key] !== undefined && item[key] !== null) {
						years.add(key);
					}
				});
			});
			return Array.from(years);
		},
		fetchData() {
			return axios.get("/api/distribution/replacement", {
				params: {
					start: this.startDate,
					end: this.endDate,
					show_performance_year: true,
					dealer: this.selectedChartDealer ? this.selectedChartDealer.loc_code : null
				},
			})
				.then(response => {
					const apiData = response.data.data;
					let transformedData = this.transformData(apiData);

					// Check if all values are 0 or missing
					const allZero = transformedData.every(item =>
						this.yearOrder.every(year => item[year] === 0 || item[year] === undefined)
					);

					if (allZero) {
						// Add placeholder data to prevent chart shifting
						transformedData = [{ month: "No Data", ...Object.fromEntries(this.yearOrder.map(y => [y, 0])) }];
					}

					return transformedData;
				})
				.catch(error => {
					console.error("Error fetching API data:", error);
					return [{ month: "No Data", ...Object.fromEntries(this.yearOrder.map(y => [y, 0])) }];
				});
		},
		transformData(apiData) {
			const chartData = [];

			// Group data by month
			apiData.forEach((item) => {
				const { bulan, tahun, value } = item;
				const monthData = chartData.find((d) => d.month === bulan);

				if (monthData) {
					monthData[tahun] = value; // Use 'tahun' as the key
				} else {
					chartData.push({
						month: bulan, // Use month as category
						[tahun]: value, // Dynamically assign year
					});
				}
			});

			return chartData;
		},
		createSeries(chart, field, name, color) {
			let series = chart.series.push(new am4charts.ColumnSeries());
			series.dataFields.valueY = field;
			series.dataFields.categoryX = "month";
			series.name = name;
			series.stacked = true;
			series.columns.template.fill = am4core.color(color);
			series.columns.template.stroke = am4core.color(color);
			series.columns.template.column.cornerRadiusTopLeft = 8;
			series.columns.template.column.cornerRadiusTopRight = 8;
			series.columns.template.width = am4core.percent(50);

			let tooltip = series.tooltip;
			tooltip.pointerOrientation = "horizontal";
			tooltip.background.cornerRadius = 5;
			tooltip.background.fill = am4core.color(color);
			tooltip.background.stroke = am4core.color(color).lighten(-0.2);
			tooltip.background.strokeOpacity = 0.8;
			tooltip.label.fill = am4core.color("#ffffff");
			tooltip.label.textAlign = "middle";

			series.tooltipText = "{name}: [bold]{valueY}[/]";

			return series;
		}
	}
};
</script>

<style>
#replacementPerformanceStackedColumnChart {
	width: 100%;
	height: 500px;
}
</style>