<template>
  <div id="NewIdChartOverallProductionYear"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
  name: "NewIdChartOverallProductionYear",
  props: {
    startDate: { type: String, required: true },
    endDate: { type: String, required: true },
    selectedYearGlobal: { type: [String, Number], default: "All Years" },
    chartData: { type: Array, required: true },
  },
  data() {
    return {
      chart: null,
      noDataLabel: null,
      colors: ["#4e80ed", "#55b586", "#c2b6f6", "#f2bc80", "#F6B6EB"],
      innerLabel: null,
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    cutoffYear() {
      return this.currentYear - 3;
    },
    yearGroups() {
      return [
        { name: this.currentYear.toString(), color: this.colors[0] },
        { name: (this.currentYear - 1).toString(), color: this.colors[1] },
        { name: (this.currentYear - 2).toString(), color: this.colors[2] },
        { name: `<=${this.cutoffYear}`, color: this.colors[3] },
        { name: "Unknown", color: this.colors[4] },
      ];
    },
  },
  watch: {
    chartData: {
      handler(newData) {
        this.updateChart(newData);
      },
      immediate: true,
    },
    selectedYearGlobal() {
      this.updateChart();
    },
  },
  mounted() {
    this.createChart();
    if (!this.chartData.length) {
      this.chart.data = [{ year_group: "Unknown", value: 0, color: this.colors[4] }];
      this.chart.legend.data = this.chart.data;
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  },
  methods: {
    createChart() {
      this.chart = am4core.create("NewIdChartOverallProductionYear", am4charts.PieChart);
      this.chart.innerRadius = am4core.percent(60);

      let pieSeries = this.chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "year_group";
      pieSeries.slices.template.propertyFields.fill = "color"; // Set color from data

      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      // Ensure 'year_group' is shown without commas in tooltips as well
      pieSeries.slices.template.tooltipText = "{category.formatNumber('#')}: {value} ({value.percent.formatNumber('#.0')}%)";
      pieSeries.tooltip.getFillFromObject = false;
      pieSeries.tooltip.background.fill = am4core.color("#000");
      pieSeries.tooltip.label.fill = am4core.color("#fff");

      // Set up legend layout for horizontal alignment
      this.chart.legend = new am4charts.Legend();
      this.chart.legend.position = "bottom";
      this.chart.legend.valueLabels.template.text = "{value.percent.formatNumber('#.0')}%";
      this.chart.legend.dataFields.name = "year_group";

      this.chart.legend.itemContainers.template.width = am4core.percent(42);
      this.chart.legend.contentAlign = "center";
      this.chart.legend.useDefaultMarker = true;
      this.chart.legend.labels.template.maxWidth = 150;
      this.chart.legend.itemContainers.template.marginBottom = 5;

      // Remove commas from year labels in the legend
      this.chart.legend.labels.template.adapter.add("text", function (text, target) {
        if (target.dataItem && target.dataItem.dataContext) {
          let yearGroup = target.dataItem.dataContext.year_group;
          return yearGroup ? yearGroup.toString().replace(/,/g, "") : text;
        }
        return text;
      });

      this.innerLabel = this.chart.seriesContainer.createChild(am4core.Label);
      this.innerLabel.horizontalCenter = "middle";
      this.innerLabel.verticalCenter = "middle";
      this.innerLabel.fontSize = 20;
      this.innerLabel.textAlign = "middle";

      this.createNoDataLabel(); // Initialize no data label
      this.updateChart(this.chartData); // Set initial data with colors
    },
    createNoDataLabel() {
      if (!this.chart) return;

      this.noDataLabel = this.chart.createChild(am4core.Label);
      this.noDataLabel.align = "center";
      this.noDataLabel.verticalCenter = "middle";
      this.noDataLabel.role = "noDataLabel";
      this.noDataLabel.html = `
        <p style="font-weight: bold; font-size: 1.5rem;">No Data Available.</p>
        <p style="margin-top: -0.5rem">Please choose another time or option</p>`;
      this.noDataLabel.hide();
    },
    toggleNoDataLabel(data) {
      const hasData = data && data.some((item) => item.value > 0);

      if (!hasData) {
        this.noDataLabel?.show();
        this.chart.seriesContainer.hide();
        this.chart.data = [];
        this.chart.legend?.hide();
      } else {
        this.noDataLabel?.hide();
        this.chart.seriesContainer.show();
        this.chart.legend?.show();
      }
    },
    updateChart(data = this.chartData) {
      if (!this.chart) return;

      const selectedYear = String(this.selectedYearGlobal || "All Years").trim();
      const specificYearsToExcludeUnknown = this.yearGroups.map((group) => group.name).filter((name) => name !== "Unknown");

      const isSpecificYear = specificYearsToExcludeUnknown.includes(selectedYear);
      const shouldShowUnknown = !isSpecificYear || selectedYear === "Unknown";

      // Normalize data to use `<=${this.cutoffYear}` format where applicable
      let filteredData = data.map((item) => {
        const normalizedYearGroup = item.year_group === "Older" || item.year_group === "Others" ? `<=${this.cutoffYear}` : item.year_group;
        return { ...item, year_group: normalizedYearGroup };
      });

      // Filter out "Unknown" for specific year selections
      if (isSpecificYear) {
        filteredData = filteredData.filter((item) => item.year_group !== "Unknown");
      }

      // Add "Unknown" if needed based on the conditions
      // if (shouldShowUnknown && !filteredData.some((item) => item.year_group === "Unknown")) {
      //   filteredData.push({ year_group: "Unknown", value: 0, color: this.colors[4] });
      // }

      this.toggleNoDataLabel(filteredData);

      // Create color mapping for each year group
      const colorMap = this.yearGroups.reduce((map, group) => {
        map[group.name] = group.color;
        return map;
      }, {});

      // Apply colors to chart data based on mapping
      let mappedData = filteredData.map((item) => {
        const color = colorMap[item.year_group] || "#cccccc"; // Default color for unmapped items
        return { ...item, color };
      });

      // Sort data based on the `yearGroups` order
      mappedData = mappedData.sort((a, b) => {
        const aIndex = this.yearGroups.findIndex((group) => group.name === a.year_group);
        const bIndex = this.yearGroups.findIndex((group) => group.name === b.year_group);
        return aIndex - bIndex;
      });

      // Assign sorted data to chart and legend
      this.chart.data = mappedData;
      // this.chart.legend.data = mappedData;

       this.chart.legend.data = mappedData.filter((item) => item.value > 0);

      // Update inner label to display highest value category
      let total = filteredData.reduce((sum, item) => sum + item.value, 0);
      let highestCategory = this.chart.data[0]?.year_group || "";
      let highestValue = this.chart.data[0]?.value || 0;

      this.chart.data.forEach((item) => {
        if (item.value > highestValue) {
          highestCategory = item.year_group;
          highestValue = item.value;
        }
      });

      this.innerLabel.text = `[bold]${highestCategory}[/]\n${((highestValue / total) * 100).toFixed(2)}%`;
    },
  },
};
</script>

<style>
#NewIdChartOverallProductionYear {
  width: 100%;
  height: 480px;
  transform: translate3d(0, 10%, 0);
}

#NewIdChartOverallProductionYear g[role="noDataLabel"] {
  transform: translate3d(16%, 32%, 10px);
}
</style>
