<template>
    <div id="ExistingIdChartPerfomanceComparison" style="width: 100%; height: 500px;"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import axios from "axios";

export default {
    name: "ComparisonChart",
    props: {
        firstRange: {
            type: String,
            required: true,
        },
        secondRange: {
            type: String,
            required: true,
        },
         region: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            chart: null,
        };
    },
    methods: {
        async fetchData() {
            const apiUrl = `/api/distribution/qr-registration/existing-id/comparison?first_range=${this.firstRange}&second_range=${this.secondRange}&region=${this.region}`;
            try {
                const response = await axios.get(apiUrl);
                const rawData = response.data?.data;
                if (rawData) {
                    return this.transformData(rawData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                return [];
            }
        },
        transformData(rawData) {
            const chartData = [];
            for (const month in rawData) {
                rawData[month].forEach((entry, index) => {
                    chartData.push({
                        month,
                        label: entry.label,
                        value1: index === 0 ? entry.value : null, // First series
                        value2: index === 1 ? entry.value : null, // Second series
                    });
                });
            }
            return chartData;
        },
        async createChart() {
            const chartData = await this.fetchData();
             // Hapus chart lama jika ada
            if (this.chart) {
                this.chart.dispose();
            }
            // create new chart
            const chart = am4core.create("ExistingIdChartPerfomanceComparison", am4charts.XYChart);
            chart.data = chartData;
            // (month)
            const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "month"; // Gunakan bulan dengan tahun sebagai kategori
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30; // Jarak antara label bulan
            // Delete Year 
            categoryAxis.renderer.labels.template.adapter.add("text", (text, target) => {
                const month = target.dataItem?.category?.split(" ")[0]; // Ambil hanya nama bulan
                return month || "";
            });
            // Sumbu nilai
            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "Values";

            // First Series
            const series1 = chart.series.push(new am4charts.ColumnSeries());
            series1.dataFields.valueY = "value1";
            series1.dataFields.categoryX = "month"; // Gunakan bulan dengan tahun sebagai kategori
            series1.name = "First Range";
            series1.columns.template.tooltipText = "{label}: [bold]{valueY}[/]";
            series1.columns.template.fill = am4core.color("#F2BC80");
            series1.columns.template.stroke = am4core.color("#F2BC80");
            series1.columns.template.column.cornerRadiusTopLeft = 5;
            series1.columns.template.column.cornerRadiusTopRight = 5;

            // Second Series
            const series2 = chart.series.push(new am4charts.ColumnSeries());
            series2.dataFields.valueY = "value2";
            series2.dataFields.categoryX = "month"; // Gunakan bulan dengan tahun sebagai kategori
            series2.name = "Second Range";
            series2.columns.template.tooltipText = "{label}: [bold]{valueY}[/]";
            series2.columns.template.fill = am4core.color("#55B586");
            series2.columns.template.stroke = am4core.color("#55B586");
            series2.columns.template.column.cornerRadiusTopLeft = 5;
            series2.columns.template.column.cornerRadiusTopRight = 5;

            chart.cursor = new am4charts.XYCursor();
            chart.legend = new am4charts.Legend();
            chart.tooltip.pointerOrientation = "vertical";
            chart.tooltip.animationDuration = 150;

            this.chart = chart;
        },
    },
    watch: {
        firstRange: "createChart",
        secondRange: "createChart",
        region: "createChart", // Watch for region changes
    },
    mounted() {
        this.createChart();
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    },
};
</script>

<style scoped>
/* Optional: Customize chart styles */
</style>