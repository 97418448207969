<template>
	<div id="replacementReasons" style="width: 100%; height: 500px"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from "axios";

am4core.useTheme(am4themes_animated);

export default {
	name: "replacementReasons",
	props: {
		startDate: { type: String, required: true },
		endDate: { type: String, required: true },
		region: { type: String, default: null },
		year: { type: String, default: null }
	},
	data() {
		return {
			chart: null
		};
	},
	watch: {
		startDate() { this.updateChart(); },
		endDate() { this.updateChart(); },
		year() { this.updateChart(); },
		region() { this.updateChart(); }
	},
	mounted() {
		this.createChart();
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
	methods: {
		createChart() {
			this.fetchData().then(chartData => {
				let chart = am4core.create(
					"replacementReasons",
					am4charts.XYChart
				);
				chart.data = chartData;

				let categoryAxis = chart.xAxes.push(
					new am4charts.CategoryAxis()
				);
				categoryAxis.dataFields.category = "month";

				let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
				valueAxis.title.text = "Registrations";

				const colors = ["#4e80ed", "#f2bc80", "#888888"];
				const reasons = ["Rusak", "Hilang", "Lain-lain"];

				reasons.forEach((reason, index) => {
					let series = chart.series.push(
						new am4charts.ColumnSeries()
					);
					series.dataFields.valueY = reason;
					series.dataFields.categoryX = "month";
					series.name = reason;
					series.stacked = true;
					series.columns.template.fill = am4core.color(colors[index]);
					series.columns.template.stroke = am4core.color(
						colors[index]
					);
					series.columns.template.column.cornerRadiusTopLeft = 5;
					series.columns.template.column.cornerRadiusTopRight = 5;
					series.columns.template.column.cornerRadiusBottomLeft = 5;
					series.columns.template.column.cornerRadiusBottomRight = 5;
					series.columns.template.width = am4core.percent(40);

					let tooltip = series.tooltip;
					tooltip.pointerOrientation = "horizontal";
					tooltip.label.fill = am4core.color("#ffffff");
					series.tooltipText =
						"{name}: [bold]{valueY}[/]";
				});

				// Add a legend
				chart.legend = new am4charts.Legend();

				chart.cursor = new am4charts.XYCursor();

				this.chart = chart;
			});
		},
		updateChart(params) {
			this.fetchData({ ...params, reason: params.reason }).then(chartData => {
				if (this.chart) {
					this.chart.data = chartData;
					this.chart.invalidateData();
				}
			});
		},
		fetchData(params = {}) {
			const queryParams = new URLSearchParams({
				start: this.startDate,
				end: this.endDate,
			});

			if (params.region) queryParams.append("region", params.region);
			if (params.manufacture_year) queryParams.append("manufacture_year", params.manufacture_year);
			if (params.reason) queryParams.append("reason", params.reason); // ✅ Tambahkan parameter reason

			console.log("Fetching data with params:", queryParams.toString()); // ✅ Debugging

			return axios.get(`/api/distribution/replacement/reasons?${queryParams.toString()}`)
				.then(response => this.transformData(response.data.data))
				.catch(error => {
					console.error("Error fetching API data:", error);
					return [];
				});
		},
		transformData(apiData) {
			const chartData = [];
			Object.keys(apiData).forEach((month) => {
				// Hapus semua angka tahun (2024, 2025, dst.)
				const cleanedMonth = month.replace(/\d{4}/, '').trim();
				const monthData = apiData[month];
				const transformedData = { month: cleanedMonth };
				monthData.forEach((data) => {
					transformedData[data.reason_replacement] = data.value;
				});
				chartData.push(transformedData);
			});
			return chartData;
		},
	}
};
</script>

<style>
#replacementReasons {
	width: 100%;
	height: 500px;
}
</style>