<template>
	<div>

		<div class="row mt-2 mb-3">
			<div class="col-md-5 col-12 row" style="padding-top: 6px">
				<div class="col mt-auto mb-auto" style="padding-right: 0">
					<h4>H2 Report Visit Monitoring</h4>
				</div>
			</div>
			<div class="col-md-1 col-12" />
			<div class="col-12 col-md-6 d-flex ml-auto w-100 align-items-center">
				<div class="col-md-3 highlightFilter"></div>

				<div class="col-md-5">
					<div class="filter-date icon fa-calendar">
						<flat-pickr v-model="dateRange.date" :config="rangeConfig" class="form-control w-100"
							:placeholder="todayPlaceholder" />
					</div>
				</div>

				<div class="col-12 col-md-4 highlightFilter">
					<button class="hso-download-button btn rounded" @click="downloadReport">
						Download CSV<i class="fas fa-file-download ml-2"></i>
					</button>
				</div>
			</div>
		</div>

		<div class="card mb-4">
			<div class="card-body">
				<div class="d-flex align-items-center mb-3">
					<h5 class="card-title mb-0 mr-2">Frequency Visit</h5>
					<div class="tooltip-container">
						<span><i class="fas fa-info-circle"></i></span>
						<div class="tooltip">
							<div class="tooltip-title">Frequency Visit</div>
							<div class="tooltip-content">Total motor that visit for service based on engine number</div>
						</div>
					</div>
					<div class="col-12 col-md-2 ml-auto">
						<multiselect v-model="selectedRegionFrequency" :options="regionOptions" label="loc_code"
							track-by="loc_code" placeholder="Region" @input="fetchData" :close-on-select="true"
							:searchable="true">
						</multiselect>
					</div>
					<div class="d-flex"></div>
				</div>
				<frequency-visit-chart :start-date="dateRange.start" :end-date="dateRange.end"
					:region="selectedRegionFrequency ? selectedRegionFrequency.loc_code : ''"></frequency-visit-chart>
			</div>
		</div>

		<b-card class="mb-4">
			<div class="d-flex align-items-center mb-3">
				<h5 class="card-title mb-0 mr-2" style="color: #9D9D9D;">Tracking Last Visit</h5>

				<!-- Region Filter -->
				<div class="col-md-2 ml-auto">
					<multiselect v-model="selectedRegion" :options="regionOptions" label="loc_code" track-by="loc_code"
						placeholder="Region" @input="fetchData" :close-on-select="true" :searchable="true">
					</multiselect>
				</div>

				<div class="input-group col-md-2">
					<div class="input-group-prepend">
						<span class="hso-search-bar-icon input-group-text">
							<i class="fas fa-search ml-2" style="color: #d8d8d8"></i>
						</span>
					</div>
					<input type="text" class="form-control hso-search-bar" placeholder="Search" v-model="searchQuery"
						@input="fetchData" />
				</div>
			</div>

			<table class="table">
				<thead>
					<tr>
						<th class="hso-table-header">Engine Number</th>
						<th class="hso-table-header">Frequency Visit</th>
						<th class="hso-table-header">Latest Visit Date</th>
						<th class="hso-table-header">Latest Visit Dealer</th>
						<th class="action-header">Action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in data" :key="item.engine_no">
						<td class="hso-table-body">{{ item.engine_no }}</td>
						<td class="hso-table-body">{{ item.freq_visit }}</td>
						<td class="hso-table-body">{{ formatDate(item.latest_visit_date) }}</td>
						<td class="hso-table-body">{{ item.latest_visit_dealer }}</td>
						<td class="hso-table-body">
							<div class="action-buttons">
								<button class="btn btn-detail rounded" @click="onDetailVisitClick(item.engine_no)">
									Lihat Detail
								</button>
								<button class="btn btn-journey rounded" @click="onVisitJourneyClick(item.engine_no)">
									<i class="fas fa-map-marker-alt"></i>
								</button>
								<button class="btn btn-download rounded" @click="downloadRowXlsx(item.engine_no)">
									Download
								</button>
							</div>
						</td>
					</tr>
					<!-- Show "No Data Available" -->
					<tr v-if="data.length === 0">
						<td colspan="100%" style="padding: 2rem 0">
							<p style="font-weight: bold; font-size: 1.5rem; line-height: 1">No Data Available.</p>
							<p style="margin-top: -0.5rem">Please choose another time or option</p>
						</td>
					</tr>
				</tbody>
			</table>

			<!-- Pagination -->
			<div class="d-flex justify-content-between align-items-center mt-3">
				<div class="hso-table-pagination">
					<!-- Showing {{ paginationStart + 1 }} to {{ paginationEnd }} of
					{{ totalItems }} entries -->
				</div>
				<div class="pagination-buttons">
					<button class="btn btn-outline-secondary mr-2" @click="previousPage" :disabled="currentPage === 1">
						Previous
					</button>
					<span class="current-page mx-2">{{ currentPage }}</span>
					<button class="btn btn-outline-secondary" @click="nextPage" :disabled="!hasMoreItems">
						Next
					</button>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import Multiselect from "vue-multiselect";
import frequencyVisitChart from "./childComponents/frequencyVisitChart";
import Tooltip from './childComponents/Tooltip.vue';
import XLSX from "xlsx";

export default {
	name: "h2-report-visit",
	metaInfo: {
		title: "H2 Report Visit Monitoring",
	},
	data() {
		return {
			modal: {
				download: false,
			},

			options: this.$store.state.filterData,

			filter: {
				start: moment().startOf('month').format('YYYY-MM-DD'),
				end: moment().format('YYYY-MM-DD'),
				by: 'freq_visit',
				order: 'desc',
			},

			rangeConfig: {
				mode: "range",
				altInput: true,
				altInputClass: "form-control input elegant-flatpickr",
				dateFormat: "Y-m-d",
				onChange: this.onDateChange,
				defaultDate: [
					moment().startOf("year").format("YYYY-MM-DD"),
					moment().format("YYYY-MM-DD"),
				], // Set default start & end dates
				date_filter: true,
				altFormat: "m/d/Y", // Changes the display format to MM/DD/YYYY
				locale: {
					months: {
						shorthand: [
							"Jan",
							"Feb",
							"Mar",
							"Apr",
							"May",
							"Jun",
							"Jul",
							"Aug",
							"Sep",
							"Oct",
							"Nov",
							"Dec",
						],
						longhand: [
							"January",
							"February",
							"March",
							"April",
							"May",
							"June",
							"July",
							"August",
							"September",
							"October",
							"November",
							"December",
						],
					},
				},
			},
			selected: {
				date_filter: true,
				date: [
					moment().startOf("month").format("YYYY-MM-DD"), // First day of the current month
					moment().format("YYYY-MM-DD"), // Today's date
				],
			},
			dateRange: {
				start: moment().startOf('month').format('YYYY-MM-DD'), // First day of the current month
				end: moment().format("YYYY-MM-DD"), // Today's date
			},

			data: [],
			currentPage: 1,
			rowsPerPage: 20,
			totalItems: 0,
			hasMoreItems: false,
			searchQuery: "",
			selectedRegion: null,
			selectedRegionFrequency: null,
			regionOptions: [],
		};
	},
	components: {
		flatPickr,
		Multiselect,
		frequencyVisitChart,
		Tooltip,
	},
	mounted() {
		this.fetchData();
		this.fetchRegions();
	},
	methods: {
		fetchRegions() {
			axios.get("/api/master/regions")
				.then(response => {
					this.regionOptions = response.data.data;
				})
				.catch(error => {
					console.error("Error fetching regions:", error);
				});
		},
		fetchData() {
			console.log("Fetching data with:", this.dateRange.start, this.dateRange.end);

			const params = {
				start: this.dateRange.start,
				end: this.dateRange.end,
				search: this.searchQuery,
				by: "freq_visit",
				region: this.selectedRegion ? this.selectedRegion.loc_code : "",
				order: "desc",
				page: this.currentPage,
				limit: this.rowsPerPage,
			};

			axios.get("/api/distribution/tracking/last-visit", { params })
				.then((response) => {
					console.log('API response:', response.data);
					this.data = response.data.data;
					this.totalItems = response.data.total || this.data.length;
					this.hasMoreItems = this.data.length === this.rowsPerPage;
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		},
		formatDate(date) {
			return moment(date).format("DD/MM/YYYY");
		},
		previousPage() {
			if (this.currentPage > 1) {
				this.currentPage--;
				this.fetchData();
			}
		},
		nextPage() {
			if (this.hasMoreItems) {
				this.currentPage++;
				this.fetchData();
			}
		},
		onDateChange(selectedDates) {
			if (selectedDates.length === 2) {
				this.dateRange.start = moment(selectedDates[0]).format("YYYY-MM-DD");
				this.dateRange.end = moment(selectedDates[1]).format("YYYY-MM-DD");
				this.fetchData(); // Ensure data updates when date changes
			}
		},
		downloadReport() {
			const params = {
				start: this.dateRange.start,
				end: this.dateRange.end,
				search: this.searchQuery,
				by: "freq_visit",
				order: "desc",
				region: this.selectedRegion ? this.selectedRegion.loc_code : "", // Added region parameter
			};

			axios
				.get("/api/distribution/tracking/download-last-visit", { params })
				.then((response) => {
					console.log('API response:', response.data);

					this.$swal.fire({
						title: 'Report Submitted',
						text: "Frequency visit request has been successfully submitted",
						icon: 'success',
						showCancelButton: true,
						confirmButtonColor: '#3085D6',
						cancelButtonColor: '#D33',
						confirmButtonText: 'To Download Page',
						cancelButtonText: 'Ok'
					}).then((result) => {
						if (result.value) {
							this.$router.push(`/report/download-report/`);
						}
					});
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		},
		downloadRowXlsx(engineNo) {
			const params = {
				search: engineNo,
				start: this.dateRange.start,
				end: this.dateRange.end,
			};

			axios.get("/api/distribution/tracking/last-visit", { params })
				.then((response) => {
					const data = response.data.data;

					const transformedData = data.map(item => ({
						engine_no: item.engine_no,
						freq_visit: item.freq_visit,
						latest_visit_date: new Date(item.latest_visit_date).toLocaleString(),
						latest_visit_dealer: item.latest_visit_dealer
					}));

					const worksheet = XLSX.utils.json_to_sheet(transformedData);

					const workbook = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

					const binaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

					const blob = new Blob([this.s2ab(binaryString)], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					});

					const url = window.URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", `visit_${engineNo}.xlsx`);
					document.body.appendChild(link);
					link.click();

					document.body.removeChild(link);
					window.URL.revokeObjectURL(url);
				})
				.catch((error) => {
					console.error("Error downloading file:", error);
				});
		},

		// Utility function to convert string to ArrayBuffer
		s2ab(s) {
			const buf = new ArrayBuffer(s.length);
			const view = new Uint8Array(buf);
			for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
			return buf;
		},
		paginate() {
			this.currentPage = 1; // Reset to the first page whenever we change the search query or data
		},
		onDetailVisitClick(engineNo) {
			this.$router.push({
				name: "H2DetailVisit",
				params: { engineNo: engineNo },
				query: {
					start: this.dateRange.start,
					end: this.dateRange.end,
				},
			});
		},
		onVisitJourneyClick(engineNo) {
			this.$router.push({
				name: "H2VisitJourney",
				params: { engineNo: engineNo },
				query: {
					start: this.dateRange.start,
					end: this.dateRange.end,
				},
			});
		},
		showTooltip() {
			this.$refs.tooltip.showTooltip()
		},
		hideTooltip() {
			this.$refs.tooltip.hideTooltip()
		},


	},
	computed: {
		filteredData() {
			return this.data.filter((item) =>
				item.engine_no.includes(this.searchQuery)
			);
		},
		paginatedData() {
			const start = (this.currentPage - 1) * this.rowsPerPage;
			const end = start + this.rowsPerPage;
			return this.filteredData.slice(start, end);
		},
		paginationStart() {
			return (this.currentPage - 1) * this.rowsPerPage;
		},
		paginationEnd() {
			return Math.min(this.paginationStart + this.data.length, this.totalItems);
		},
	},
	watch: {
		searchQuery() {
			this.currentPage = 1;
			this.fetchData();
		},
	},
};
</script>

<style scoped>
/* Ensure the flat-pickr input field has the same background color as the icon */
.flat-pickr.form-control {
	background-color: #FFFFFF;
	/* Match the background color of the icon */
	border-radius: 0 5px 5px 0;
	/* Remove border radius on the left side */
	border-left: none;
	/* Remove the left border to avoid double borders */
}

/* Ensure the input group has no border radius on the left side */
.input-group .form-control:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

/* Ensure the input group prepend has no border radius on the right side */
.input-group-prepend .input-group-text {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.hso-search-bar {
	border: 1px solid #ced4da;
	border-left: none;
	border-radius: 0 5px 5px 0;
	/* Right side rounded corners */
}

.hso-search-bar-icon {
	border: 1px solid #ced4da;
	border-right: none;
	border-radius: 5px 0 0 5px;
	/* Left side rounded corners */
}

.hso-date-icon {
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	border-right: none;
	border-radius: 5px 0 0 5px;
	color: #4B4B4B;
	/* Left side rounded corners */
}

.action-header {
	width: 1%;
	/* This forces the column to be as narrow as possible */
	white-space: nowrap;
	color: #4B4B4B;
	background-color: #FFFFFF;
	text-align: center;
	padding-right: 10px;
	/* Kurangi padding untuk mendekatkan */
}

.action-body {
	width: 1%;
	/* This forces the column to be as narrow as possible */
	white-space: nowrap;
	color: #4B4B4B;
	background-color: #FFFFFF;
	border-top: none;
	border-bottom: none;
	text-align: center;
	/* Set ke center */
	display: flex;
	justify-content: center;
	align-items: center;
}

.filter-date .form-control {
	background-color: #3c97fe !important;
	color: #FFFFFF;
	/* Jika teksnya kurang kontras */
}

.action-buttons {
	display: flex;
	gap: 10px;
	justify-content: flex-start;
	align-items: center;
	border-radius: 50px;
}

.btn-detail {
	height: 40px;
	width: 150px;
	border: 1px solid #efefef;
}

.btn-journey {
	height: 40px;
	width: 40px;
	border: 1px solid #267ce5;
	display: flex;
	/* Use flexbox for centering */
	align-items: center;
	/* Center vertically */
	justify-content: center;
	/* Center horizontally */
	padding: 0;
	/* Remove any default padding */
}

.btn-journey i {
	color: #267ce5;
	font-size: 16px;
	/* Adjust the font size if needed */
	margin: 0;
	/* Ensure no margins are added */
}

.btn-download {
	height: 40px;
	width: 150px;
	border: 1px solid #efefef;
}

.hso-download-button {
	margin-top: 12px;
	height: 40px;
	margin-top: 0px;
	color: #2F2F2F;
	background-color: #FFFFFF;
	box-shadow: none;
	width: 100%;
	white-space: nowrap;
}

.hso-table-header {
	color: #4b4b4b;
	background-color: #ffffff;
	text-align: left;
}

.hso-table-body {
	border-bottom: none;
	border-top: none;
	text-align: left;
}

.hso-table-pagination {
	color: #cbcbcb;
}

.tooltip-container {
	position: relative;
	display: inline-block;
}

.tooltip {
	visibility: hidden;
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	width: 200px;
	opacity: 0;
	transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
	visibility: visible;
	opacity: 1;
}

.tooltip-title {
	font-weight: bold;
	margin-bottom: 5px;
}

.tooltip-content {
	font-size: 0.9em;
	color: #666;
}
</style>